.jsonata-input-pane :global .monaco-editor-background,
.jsonata-input-pane :global .margin-view-overlays {
  background-color: #fffffa !important;
}

.jsonata-input-pane :global .overflow-guard {
  border: solid #e9e9e9;
}

.jsonata-result-pane :global .monaco-editor-background,
.jsonata-result-pane :global .margin-view-overlays {
  background-color: #f5f5f5 !important;
}
